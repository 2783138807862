$(document).ready(function() {
  nav();
  scroll()
  upButtonFunc();
  popup();
});
$(window).resize(function () {
  pageWidth = $('body').innerWidth();
});
$(window).scroll(function() {
  upButtonFunc();
});

//global
var pageWidth = $('body').innerWidth(),
    upButton = $('.up-button');

//mobile nav
function nav() {
  var navButton = $('.mobile-button'),
      nav = $('.mobile-contacts');

  navButton.click(function(event) {
    event.preventDefault();
    nav.toggleClass('mobile-contacts_active');
    stateCheck();
  })

  function stateCheck() {
    if(nav.hasClass('mobile-contacts_active')) {
      navButton.addClass('mobile-button_active');
    } else {
      navButton.removeClass('mobile-button_active');
    }
  }
  $(window).resize(function () {
    if(pageWidth>768) {
      nav.removeClass('mobile-contacts_active');
      stateCheck();
    }
  });
}
//scroll
function scroll() {
  $(".scroll-button").on("click", function (event) {
		event.preventDefault();
		var id  = $(this).attr('href'),
			top = $(id).offset().top;
		$('body,html').animate({scrollTop: top}, 1000);
	});
}
//upButton
function upButtonFunc() {
  upButton.unbind().on('click', function(event) {
    event.preventDefault();
    $('html, body').animate({scrollTop: 0},500);
    console.log('ddd');
  })
  if ($(window).scrollTop() > 200) {
    upButton.fadeIn(500);
  } else {
    upButton.fadeOut(500); 
  }
}
//popup
function popup() {
  var openButton = $('.popup-link');
  var closeButton = $('.popup__overlay, .popup__close');
  var popup = $('.popup');

  function closePopup() {
    popup 
      .animate({opacity: 0}, 200, 
        function(){ 
          $(this).css('display', 'none');
      });
    $('body, html').css('overflow', 'visible');
    scrollLock.show($("body"));
  }

  openButton.click( function(event){
    event.preventDefault();
    $($(this).attr('href')).css('display', 'block').animate({opacity: 1}, 200);
    scrollLock.hide($("body"));
    $('body, html').css('overflow', 'hidden');
  });
  closeButton.click( function(){
    closePopup();
  });
}